import "../../styles/SupportPage.scss";
import { Link } from "react-router-dom";
import SupportPageForm from "./SupportPageForm";


const SupportPage = () => {
  return (
    <div className="support-page-wrapper">
      <div className="support-page">
      <div className="support-page-text">
        <h1 className="support-page-text-header">Grid Support</h1>
        <div className="support-page-text-body">
          <p>Check out our privacy policy and community guidlines!</p>
          <Link className="nav-link" to="/privacy">
            Privacy Policy
          </Link>
          <Link className="nav-link" to="/terms">
            Terms of Use
          </Link>
          <Link className="nav-link" to="/community">
            Community Guidlines
          </Link>
          <Link className="nav-link" to="/tos/gridreferral">
            Referral Contest Program
          </Link>
          <p>
            If you still have any questions or issues, please reach out to{" "}
            <a href="mailto:support@dateongrid.com">support@dateongrid.com</a>{" "}
            or send a message below!
          </p>
        </div>
      </div>
        <SupportPageForm />
      </div>
    </div>
  );
};

export default SupportPage;
