import React from "react";

import "../../styles/Policy.scss"

const TermsOfUse = () => {
  return <div className="policy-wrapper">
    <div className="policy-content">
      <h1 className="policy-title">Terms of Use</h1>
      
      <p>Welcome to Grid! Grid ("Company," "we," "our," or "us") provides an interactive platform connecting users for social and dating experiences (the "Services"). These Terms of Use ("Terms") govern your access to and use of the Grid mobile application, website, and related services.</p>

      <p>By creating a Grid account or otherwise using our Services, you agree to these Terms. If you do not agree, do not use our Services.</p>

      <h2 className="section-title">1. ACCEPTANCE OF TERMS</h2>
      <div className="section">
        <p>By using Grid, you affirm that:</p>
        <ul>
          <li>You have read, understood, and agree to be bound by these Terms and our Community Guidelines.</li>
          <li>You are legally capable of entering into a binding agreement.</li>
          <li>You meet the eligibility criteria outlined below.</li>
        </ul>
        <p>We reserve the right to modify these Terms at any time. If substantial changes are made, we will notify you through in-app messages, email, or other methods. Your continued use after such changes constitutes acceptance of the updated Terms.</p>
      </div>

      <h2 className="section-title">2. ELIGIBILITY</h2>
      <div className="section">
        <p>To access Grid, you must:</p>
        <ul>
          <li>Be at least 18 years old or the age of majority in your jurisdiction, whichever is higher.</li>
          <li>Not have been previously suspended or banned by Grid.</li>
          <li>Not be a registered sex offender or have convictions related to violence, abuse, harassment, stalking, fraud, human trafficking, kidnapping, child abuse, domestic violence, hate crimes, or terrorism.</li>
          <li>Not use Grid for commercial or fraudulent purposes.</li>
        </ul>
        <p>We reserve the right to reject, block, or terminate accounts at our sole discretion without prior notice or liability.</p>
      </div>

      <h2 className="section-title">3. ACCOUNT REGISTRATION & SECURITY</h2>
      <div className="section">
        <ul>
          <li>Provide accurate and truthful information when creating your account.</li>
          <li>Safeguard your login credentials. Notify us immediately at support@dateongrid.com if you suspect unauthorized account access.</li>
          <li>Do not share your account, allow others to access it, or create multiple accounts.</li>
        </ul>
        <p>We are not liable for unauthorized access due to your failure to secure your account credentials.</p>
      </div>

      <h2 className="section-title">4. SUBSCRIPTIONS, PURCHASES & PAYMENTS</h2>
      <div className="section">
        <p>Certain features of <strong>Grid</strong> may require a <strong>paid subscription</strong> or <strong>one-time purchases</strong>.</p>

        <h3 className="subsection-title">A. Payment Terms</h3>
        <ul>
          <li>Payments are processed via Apple and billed on a weekly, monthly or tri-monthly basis.</li>
          <li>Prices, features, and billing cycles are outlined in our in-app pricing page.</li>
          <li>By subscribing, you authorize <strong>Grid</strong> to charge your payment method for recurring fees unless you cancel.</li>
        </ul>

        <h3 className="subsection-title">B. Cancellation & Refunds</h3>
        <ul>
          <li>You can cancel your subscription at any time via Apple's subscription cancellation page, but payments are <strong>non-refundable</strong>.</li>
          <li>If you cancel, you will retain access to paid features until the end of your billing cycle.</li>
          <li>We <strong>do not</strong> offer refunds, except where required by law.</li>
        </ul>
      </div>

      <h2 className="section-title">5. USER CONDUCT & COMMUNITY GUIDELINES</h2>
      <div className="section">
        <p>To maintain a safe environment, you agree not to:</p>
        <ul>
          <li>Share inappropriate content (hateful, discriminatory, sexually explicit).</li>
          <li>Harass, stalk, or impersonate others.</li>
          <li>Solicit money or gifts.</li>
          <li>Attempt unauthorized access or disruption of services.</li>
        </ul>
        <p>We reserve the right to suspend or terminate your account without prior notice for violating these Terms or our Community Guidelines.</p>
      </div>

      <h2 className="section-title">6. BACKGROUND CHECKS & SAFETY</h2>
      <div className="section">
        <p>We may, at our discretion, perform criminal background checks using publicly available data. These checks are limited and should not replace your own caution and judgment. Grid does not guarantee the accuracy or completeness of background checks and is not liable for actions taken by users.</p>
      </div>

      <h2 className="section-title">7. USER CONTENT & LICENSE</h2>
      <div className="section">
        <ul>
          <li>You retain ownership of your content but grant Grid a non-exclusive, royalty-free, perpetual, worldwide license to use your content.</li>
          <li>You confirm having rights to share your content and will indemnify Grid from related claims.</li>
          <li>We may remove or moderate content at our discretion.</li>
        </ul>
        <p>Report violations via support@dateongrid.com.</p>
      </div>

      <h2 className="section-title">8. PRIVACY & DATA USAGE</h2>
      <div className="section">
        <p>Your use of Grid is subject to our Privacy Policy. We collect data as detailed in the Privacy Policy.</p>
      </div>

      <h2 className="section-title">9. TERMINATION & ACCOUNT DEACTIVATION</h2>
      <div className="section">
        <p>We may suspend or terminate your account without prior notice for violations, harmful behavior, or fraudulent activities. You may voluntarily delete your account via account settings. Some data may be retained for compliance purposes.</p>
      </div>

      <h2 className="section-title">10. DISCLAIMERS & LIMITATION OF LIABILITY</h2>
      <div className="section">
        <h3 className="subsection-title">A. No Guarantees</h3>
        <ul>
          <li>Grid provides services "as is" without warranties.</li>
          <li>We do not guarantee matches, interactions, or outcomes.</li>
        </ul>

        <h3 className="subsection-title">B. Limitation of Liability</h3>
        <ul>
          <li>Grid, to the maximum extent permitted by law, disclaims all liability for indirect, incidental, consequential, punitive, or special damages arising from your use of the Services.</li>
          <li>Grid's total liability under these Terms shall not exceed $100.</li>
        </ul>
        <p>You hereby expressly release Grid from any claims arising from interactions with other users, including claims related to criminal acts, personal injuries, or emotional distress.</p>
      </div>

      <h2 className="section-title">11. DISPUTE RESOLUTION & ARBITRATION AGREEMENT</h2>
      <div className="section">
        <p>All disputes between you and Grid shall be resolved through binding arbitration rather than court. Arbitration is individual and not through class or representative actions. You may opt-out of arbitration by contacting us within 30 days of accepting these Terms at support@dateongrid.com.</p>
      </div>

      <h2 className="section-title">12. INDEMNIFICATION</h2>
      <div className="section">
        <p>You agree to indemnify and hold Grid harmless from any claims, liabilities, damages, or expenses arising from your use of the Services, your content, violation of these Terms, or unlawful actions.</p>
      </div>

      <h2 className="section-title">13. THIRD-PARTY SERVICES</h2>
      <div className="section">
        <p>Grid is solely responsible for the application, not third-party app stores. App store providers have no obligation for app maintenance or user support.</p>
      </div>

      <h2 className="section-title">14. GOVERNING LAW</h2>
      <div className="section">
        <p>These Terms are governed by the laws of the State of New York, United States, without regard to its conflict-of-law principles.</p>
      </div>

      <h2 className="section-title">15. CHANGES TO THESE TERMS</h2>
      <div className="section">
        <p>We may update these Terms periodically. Significant changes will be communicated via email or in-app notifications. Continued use after updates implies acceptance of the new terms.</p>
      </div>

      <h2 className="section-title">16. CONTACT US</h2>
      <div className="section">
        <p>For questions or concerns:</p>
        <p>📩 Email: support@dateongrid.com</p>
      </div>
    </div>
  </div>;
};

export default TermsOfUse;
