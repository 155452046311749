import React from "react";

import "../../styles/Policy.scss"

const PrivacyPolicy = () => {
  return <div className="policy-wrapper">
    <div className="policy-content">
    <h1 className="policy-title">Privacy Policy for Grid</h1>
    <p className="last-updated">Last Updated: January 8, 2024</p>

    <p>Welcome to Grid ("we," "us," or "our"). This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you use our dating application and services.</p>

    <h2 className="section-title">1. Information We Collect:</h2>
    <div className="section">
        <h3 className="subsection-title">a. Account Information:</h3>
        <p>- When you create an account, we collect information such as your name, email address, and phone number.</p>

        <h3 className="subsection-title">b. Profile Information:</h3>
        <p>- Users may provide additional information for their profiles, including but not limited to photos, age, gender, location, and personal interests.</p>

        <h3 className="subsection-title">c. Communication Data:</h3>
        <p>- We collect data exchanged during communication within the app, including messages and other content shared between users.</p>

        <h3 className="subsection-title">d. Device Information:</h3>
        <p>- We collect information about the devices you use to access our app, including device type, operating system, and unique device identifiers.</p>

        <h3 className="subsection-title">e. Usage Data:</h3>
        <p>- We gather information about your interactions with the app, such as the features you use, pages visited, and actions taken.</p>
    </div>

    <h2 className="section-title">2. How We Use Your Information:</h2>
    <div className="section">
        <h3 className="subsection-title">a. Providing Services:</h3>
        <p>- We use your information to provide and improve our dating services, including matching users and facilitating communication.</p>

        <h3 className="subsection-title">b. Personalization:</h3>
        <p>- We may use your data to personalize your experience, recommend matches, and display relevant content.</p>

        <h3 className="subsection-title">c. Communication:</h3>
        <p>- Your information is used to facilitate communication between users and provide notifications related to app activity.</p>

        <h3 className="subsection-title">d. Analytics:</h3>
        <p>- We analyze user interactions to enhance the app's functionality, performance, and user experience.</p>
    </div>

    <h2 className="section-title">3. Sharing Your Information:</h2>
    <div className="section">
        <h3 className="subsection-title">a. With Other Users:</h3>
        <p>- Certain information, such as profile details and communication data, may be shared with other users as part of the app's features.</p>

        <h3 className="subsection-title">b. Service Providers:</h3>
        <p>- We may share your information with third-party service providers who assist us in delivering and improving our services.</p>
    </div>

    <h2 className="section-title">4. Your Choices:</h2>
    <div className="section">
        <h3 className="subsection-title">a. Profile Settings:</h3>
        <p>- You can manage your profile settings to control the information you share with other users.</p>

        <h3 className="subsection-title">b. Notifications:</h3>
        <p>- You can customize your notification preferences within the app.</p>
    </div>

    <h2 className="section-title">5. Security:</h2>
    <div className="section">
        <h3 className="subsection-title">a. Data Security:</h3>
        <p>- We employ industry-standard security measures to protect your data from unauthorized access and misuse.</p>
        <p>- If you delete your account and request the deletion of your data, we will comply with the request in a timely manner.</p>
    </div>

    <h2 className="section-title">6. Updates to this Privacy Policy:</h2>
    <div className="section">
        <h3 className="subsection-title">a. Changes:</h3>
        <p>- We may update this Privacy Policy periodically. Please review it regularly for any changes.</p>
    </div>

    <h2 className="section-title">7. Contact Us:</h2>
    <div className="section">
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at support@dateongrid.com.</p>
    </div>

    <p>By using Grid, you agree to the terms outlined in this Privacy Policy.</p>
</div>
  </div>;
};

export default PrivacyPolicy;
